export default {
  action: {
    back: "后退",
    buy: "购买",
    cancel: "取消",
    changeLanguage: "更换语言",
    checkResult: "查看成绩",
    claim: "领取",
    claimed: "领取了",
    claimReward: "领取奖励",
    clickToContinue: "点击继续",
    clickToClaim: "点击索取",
    continue: "继续",
    delete: "删除",
    done: "OK",
    draw: "画",
    enter: "进入",
    game: "尝试完整版游戏",
    go: "选择",
    goOn: "继续",
    hatch: "敲一敲",
    home: "回家",
    info: "资讯",
    joinNow: "马上参加",
    login: "登入",
    loginAgain: "再次登入",
    loginAsStudent: "以学生身份登入",
    loginAsTeacher: "以教师身份登入",
    logout: "退出",
    new: "增添",
    next: "继续",
    nextTime: "下一次吧",
    noThx: "不，谢谢",
    orderBy: "排序",
    ok: "OK",
    play: "游玩",
    playAgain: "再玩一遍",
    refresh: "刷新",
    regenerateQuestion: "更新问题",
    register: "注册",
    remove: "消除",
    returnHome: "返回主页",
    retry: "重试",
    rewardClaimed: "已领取奖励",
    save: "保存",
    scoreboard: "记分牌",
    search: "搜索",
    select: "选择",
    selected: "已选中",
    share: "分享",
    skip: "跳过",
    skipTutorial: "跳过教程",
    start: "开始",
    testPlay: "试玩",
    trade: "贸易",
    tryAgain: "再试一次",
    unlock: "开锁",
    view: "查阅",
    viewSample: "查阅例题",
    whatsapp: "Whatsapp 我们",
  },
  api: {
    response: {
      no_data: "没有相关数据。",
    },
  },
  buildingEntranceDialogues: {
    welcomeBugCatcher_1: "欢迎来Bug Catcher！",
    welcomeBugCatcher_2: "欢迎来Bug Catcher！",
    welcomeBugCatcher_3: "欢迎来Bug Catcher！",
    welcomeEnergy_1: "来来，免费充电！",
    welcomeEnergy_2: "来来，免费充电！",
    welcomeEnergy_3: "来来，免费充电！",
    welcomeEvents_1: "要一份热腾腾的热狗吗？",
    welcomeEvents_2: "你也来看热闹啊。",
    welcomeEvents_3: "今天有什么活动呢？",
    welcomeHouse_1: "欢迎回来。",
    welcomeHouse_2: "欢迎回来。",
    welcomeHouse_3: "欢迎回来。",
    welcomeLuckyegg_1: "今天蛋里会有什么呢？",
    welcomeLuckyegg_2: "今天蛋里会有什么呢？",
    welcomeLuckyegg_3: "今天蛋里会有什么呢？",
    welcomeMission_1: "欢迎来到家庭作业！",
    welcomeMission_2: "欢迎来到家庭作业！",
    welcomeMission_3: "欢迎来到家庭作业！",
    welcomeReport_1: "欢迎来到报告卡！",
    welcomeReport_2: "欢迎来到报告卡！",
    welcomeReport_3: "欢迎来到报告卡！",
    welcomeSchool_1: "找其他同学吗？",
    welcomeSchool_2: "找其他同学吗？",
    welcomeSchool_3: "找其他同学吗？",
    welcomeShop_1: "欢迎光临！",
    welcomeShop_2: "我们有许多漂亮的服装哦！",
    welcomeShop_3: "只有我们有卖服装。",
  },
  miniGames: {
    general: {
      cache: "缓存",
      cacheOn: "缓存已经启动",
      cacheOff: "缓存已经停止",
      continue: "点击开始",
      go: "开始",
      loading: "加载中",
      newGame: "新的游戏",
      ok: "OK",
      on: "启动",
      off: "停止",
      ready: "准备",
      resume: "继续",
      round: "回合",
      score: "分数",
      next: "继续",
      recommendationTxt : "我们在继续之前先通 过视频学习一下。",
      recommendationBtn : "立即观看"
    },
    mining: {
      careful: "小心!",
      cleared: "采掘成功!",
      gemLostBoss: "宝石没了!",
      gemLost: "宝石没了!",
      hurryUp: "快点快点!",
      timeFreeze: "时间冻结!",
      timeUp: "时间结束!",
      warning: "警告",
      warningMsg: "宝石小偷出现了!",
      tutorial: {
        dialog_1: "欢迎来到Mining Mania！",
        dialog_2: "让我们来学习如何游玩。",
        dialog_3: "单击【铁镐】按键开始。",
        dialog_4: "请选择正确的答案。",
        dialog_5_a: "太好了， 你的回答是正确的。",
        dialog_5_b: "哎呀.. 答案不正确。",
        dialog_6_a: "拿好这巨大的炸弹吧！",
        dialog_6_b: "可惜，只获得小小的炮竹。。。",
        dialog_7: "卡嘣...！",
        dialog_8: "哇， 你发现了一个被埋藏的宝石。",
        dialog_9: "赶快把宝石挖出来。",
        dialog_10: "不过你动作要快哦， 当计时结束时， 墙壁将会倒塌。",
        dialog_11: "加油， 祝你好运！",
        dialog_12_a: "时间到了！ 只能下次再试了。",
        dialog_12_b: "不好了！ 宝石被你敲破了。",
        dialog_12_c: "恭喜！ 你得到了一颗宝石。 棒棒哒！",
        dialog_13: "让我们再试一次吧。",
        dialog_14: "结束啦！ 您已完成了教程。",
        dialog_15: "祝你好运， 继续找到更多的宝石哦。",
      },
    },
    foraging: {
      failed: "失败",
      failedMsg: "稀有水果不见了",
      success: "成功",
      successMsg: "你收集了稀有水果",
      stun: "晕眩",
      warning: "警告",
      warningMsg: "水果小偷出现了！",
      q_msg_1: "完美",
      q_msg_2: "太棒了",
      q_msg_3: "棒棒的",
      q_msg_4: "不错",
      q_msg_5: "可惜了",
      tutorial: {
        dialog_1: "欢迎来到 Foraging Fest。",
        dialog_2: "让我们学习如何游玩。",
        dialog_3: "点击开始。",
        dialog_4: "你的工作是收集新鲜的苹果。",
        dialog_5: "同时， 要避开小蛇  毛毛虫和烂苹果。",
        dialog_6: "使用箭头按钮来移动。",
        dialog_7: "做得好。",
        dialog_8: "让我们开始吧。",
        dialog_9: "哇，你充满了能量！",
        dialog_10: "回答问题并使用你的能力。",

        dialog_11: "选择正确的答案。",
        dialog_12_a: "太好了，你的回答正确。",
        dialog_12_b: "哎呀……答案不正确。",
        dialog_13_a: "使用看你的能力吧。",
        dialog_13_b: "使用看你的能力吧。",
        dialog_14: "我们再试一次吧。",
        dialog_15: "干得好！ 您已完本了游戏教程。",
        dialog_16: "祝你好运！",
      },
    },
    cooking: {
      boss: "老板来了",
      warning: "警告",
      tutorial: {
        dialog_1: "欢迎来到 Cooking Craze",
        dialog_2: "让我们来学习如何玩吧！",
        dialog_3: "你的工作是为顾客 提供他们喜欢的食物",
        dialog_4: "不同的食物有不同的分数哦",
        dialog_5: "不过， 记得不要让顾客等待太久哦",
        dialog_6: "好的， 我们开始吧！",
        dialog_7: "首先点击食材开始烹饪",
        dialog_8: "然后等待食物煮熟",
        dialog_9: "现在， 将食物拖给顾客",
        dialog_10: "做得好， 顾客很满意！",
        dialog_11: "接下来， 让我们把食物煮过头。",
        dialog_12: "哎呀， 炉子着火了！",
        dialog_13: "快点！ 拖动灭火器将火扑灭",
        dialog_14: "然后将烧焦的 食物拖入垃圾桶",
        dialog_15: "好了， 让我们继续吧……",
        dialog_16: "哇， 你的技能条已经满了",
        dialog_17: "回答问题来 使用你的技能！",
        dialog_18: "选择正确的答案。",
        dialog_19_a: "太好了， 你的答案是正确的",
        dialog_19_b: "答错了",
        dialog_20: "使用你的技能吧……",
        dialog_21_a: "快点，喂饱所有的顾客！",
        dialog_21_b: "噗..意外发生了。",
        dialog_22: "快救火啊！",
        dialog_23: "我们再试一次吧。",
        dialog_24: "做得好！ 您已完成了游戏教程。",
        dialog_25: "祝你好运！",
      },
    },
    towerClimbing: {
      blueStarError: "蓝色星星不够多",
      floor: "地面",
      footprintError: "脚印不够",
      pickOne: "选一个",
    },
    kombat: {
      boss: "海盗船长出现了！！！",
      warning: "警告",
      lost: "失败",
      fight: "战斗",
      won: "胜利",
      tutorial: {
        dialog_1: "欢迎来到 BIBABO",
        dialog_2: "让我们来学习如何玩吧！",
        dialog_3: "哦，发现了海盗！",
        dialog_4: "让我们打败他们吧。",
        dialog_22: "这些是你的生命值 - 不要让它们变成 0 哦。",
        dialog_5: "点击开始攻击栏。",
        dialog_6: "再次点击停止。",
        dialog_7: "填满两个攻击栏。",
        dialog_8_a: "嘿嘿，吃我的魔法吧!!!",
        dialog_8_b: "你的魔法失败了……",
        dialog_9: "哎呀，海盗反击了。",
        dialog_10: "来，我们继续攻击海盗。",
        dialog_11: "哦，海盗受伤了。",
        dialog_12: "准备好使用你的大魔法攻击。",
        dialog_13: "点击继续。",
        dialog_14: "选择正确的答案。",
        dialog_15_a: "太好了，你的答案是正确的",
        dialog_15_b: "答错了",
        dialog_16_a: "你的魔法现在更强大了！",
        dialog_16_b: "哦不，你错过了机会。",
        dialog_21: "攻击!!!",
        dialog_17_a: "干得好，你打败了海盗！",
        dialog_17_b: "哦不，你输了!!!",
        dialog_18: "下次再战吧。",
        dialog_19: "您已完成了游戏教程。",
        dialog_20: "祝你好运！",
      },
    },
    fishing: {
      boss: "发现大鱼！！！",
      fish_1: "箱子鱼",
      fish_2: "虾",
      fish_3: "蛤蜊",
      fish_4: "多利鱼",
      trash: "垃圾",
      warning: "警告",
      start: "开始",
      tutorial: {
        dialog_1: "欢迎大家来参加 Fishing Frenzy!",
        dialog_2: "我是Hamochi，我来教你如何钓鱼吧！",
        dialog_3: "点击黄色的大灯泡按钮开始钓鱼。",
        dialog_4_1: "鱼儿上钩啦! ",
        dialog_4_2: "看看它出了什么难题给我们!",
        dialog_5: "选择正确的答案。",
        dialog_6_a: "太好了，你的答案是正确的",
        dialog_6_b: "哎呀！ 答错了。",
        dialog_12_a: "你的钓鱼竿现在更坚固了！",
        dialog_12_b: "不好，你的鱼竿变弱了！",
        dialog_7: "快，按下黄色的大按钮把鱼拉上来！",
        dialog_8_a: "恭喜，你成功钓到鱼了！",
        dialog_8_b: "可惜, 鱼儿游走了...",
        dialog_9: "我们再练习一下吧！",
        dialog_10: "干得好，您已经完成了钓鱼教程。",
        dialog_11: "祝你能够收获满满, 满载而归!",
      },
    },
  },
  model: {
    filterBy: {
      all: "全部",
    },
    name: {
      chapter: "单元",
      chapters: "单元",
      checkpoint: "课题",
      checkpoints: "课题",
      classroom: "班级",
      classrooms: "班级",
      event: "比赛",
      events: "比赛",
      game: "游戏",
      mission: "功课",
      missions: "功课",
      prize: "奖品",
      prizes: "奖品",
      question: "题目",
      questions: "题目",
      results: "公布成绩",
      school: "学校",
      schools: "学校",
      series: "系列",
      student: "学生",
      students: "学生",
      submissions: "题卷",
      syllabus: "课程纲要",
      syllabuses: "课程纲要",
      winner: "得奖者",
      winners: "得奖者",
    },
    prop: {
      accuracy: "精准度",
      attempt: "作答次数",
      bought: "买",
      corrects: "正确",
      date: "日期",
      dateDue: "截止日期",
      dateEnd: "结束日期",
      dateStart: "开始日期",
      fullname: "姓名",
      gameCode: "游戏代码",
      high_score: "最高得分",
      level: "关卡",
      mobile: "手机号码",
      name: "名字",
      no: "#",
      password: "密码",
      quantity: "数量",
      rewards: "奖励",
      round: "回合",
      schoolCode: "学校代码",
      score: "得分",
      stars: "星数",
      status: "状态",
      time: "时间",
      timeEnd: "时间结束",
      timestampEnd: "结束",
      timestampStart: "开始",
      totalAttempt: "作答次数",
      totalScore: "总得分",
      totalStar: "总星数",
      weakness: "弱点",
    },
    value: {
      status: {
        absent: "缺席",
        coming: "未开始",
        joined: "参与中",
        ongoing: "进行中",
        past: "结束了",
      },
    },
  },
  route: {
    PageEntranceStudentMain: "主页",
  },
  string: {
    about: "关于",
    all: "全部",
    achievement: "成就",
    are_you_sure: "你确定吗？",
    audio: "音乐",
    audioGraphics: "音乐和画质",
    autofix: "自动修复",
    bgMusic: "背景音乐",
    blankSpace: "",
    body: "身体",
    bug: "昆虫",
    doll: "娃娃",
    cancel: "取消",
    catch: "抓住",
    chapter: "单元",
    change: "选择学校",
    claimed: "领取了",
    code: "游戏代码",
    color: "颜色",
    collection: "收藏",
    completed: "完成了",
    coming_on: "开始于",
    current: "目前",
    confirm_msg: "你想结束游戏吗？",
    days: "天",
    days_left: "天后结束",
    easy: "简单",
    empty: "空的",
    empty_msg: "收件箱是空的！",
    emptyNameError: "请输入姓名。",
    energyClaimed: "你今天已经认领了你的能量。",
    ends_in: "结束于",
    expired: "已过期",
    expires_in: "消失于",
    eventNotStarted: "比赛还未开始。",
    event_over: "本次活动到此结束！",
    exit: "结束游戏",
    energyRechargeable: "每天补充能量！",
    energySuccess: "充值成功！",
    failed: "失败的！",
    finished: "结束了",
    finisherGiftTitle: "完赛礼物",
    finisherGiftMsg1: "恭喜您完成本次活动！",
    finisherGiftMsg2: "您的礼物正在送往：",
    finisherGiftMsg3: "您的礼物已送达！请到学校领取。",
    featureNotAvailable: "还无法使用此功能。",
    fishingTutorial: "钓鱼教程",
    fishingTutorialCompleted: "您已完成钓鱼教程课程",
    fishingTutorialIncomplete: "请完成教程以解锁此级别",
    fish: "鱼类",
    fish2: "鱼类",
    fruit: "水果",
    food: "料理",
    game_loading_msg: "游戏开始中。。。 如果卡在，请重试。",
    gameScore: "游戏得分",
    gem: "矿物宝石",
    get: "得到",
    gifts_error: "只有学生可领取礼物。",
    giftDescription: "集齐所有星星就有小礼物哟。加油！",
    hall: "大厅",
    hard: "困难",
    highGraphics: "高清画质",
    info: "信息",
    high_score: "最高得分:",
    league_reward: "联赛奖励",
    level: "关卡",
    language: "语言",
    lastLogin: "最近登入",
    levels: "关卡",
    lockedBuilding: "会是什么呢？",
    learnMoreEvent: "了解有关此活动的更多信息",
    manyGifts: "很多礼物🎁等着你！",
    medium: "中等",
    mouth: "嘴巴",
    month_theme: "本月主题：",
    myPrize: "我的奖品",
    no: "不",
    noStudents: "这个联赛等级没有学生",
    noItem: "空空的，没有东西。",
    noPick: "没有选择",
    notCompleted: "未达成",
    noThanks: "不，谢谢！",
    open: "打开",
    others: "其他",
    own: "已拥有",
    page: "页面",
    question: "题目",
    question_prefix: "问题",
    rank: "排名",
    ranking: "排行榜",
    rankingClassroom: "班级排名",
    rankingEvent: "活动排名",
    read_rules: "阅读活动指南",
    remove: "消除",
    refresh: "刷新",
    resetIn: "更新于",
    result: "成绩",
    rewards_claimed: "领取奖励！",
    round: "回合",
    requiredHigh: "需要快的网速。",
    rules: "指南",
    sample_question: "例题",
    save: "保存",
    saving: "保存......",
    school: "学校",
    schoolYear_prefix: "",
    schoolYear_suffix: " 年级",
    select_year: "选择年级",
    select_hole: "请点击其中一个钓鱼洞口。",
    selectKeyError: "请点击密码钥匙。",
    settings: "设定",
    sponsors: "赞助商",
    sfx: "音效",
    show_all: "显示全部",
    show_less: "显示关注",
    starts: "{count} 开始",
    starts_in: "倒数",
    start_now: "开始",
    success: "成功！",
    theme: "主题",
    tutorial: "游戏教程",
    ticketError: "回答劵不足。每天会有新的回答劵。今天先休息，明天见。",
    total: "总共",
    totalScore: "总得分",
    topics: "课题",
    updateImages: "更新图像",
    upload_art: "上传你的作品！",
    view_result: "查看结果",
    volume: "音量",
    wear: "穿上",
    warning: "警告！",
    yes: "确定",
    mail: "邮件",
    year: "{year} 年级",
    you_have_mail: "你有邮件",
    you_got: "恭喜你得到",
    you_collected: "你收集了",
    you_missed_a: "你错过了稀有宝石",
    you_missed_b: "你错过了稀有水果",
    you_missed_c: "你错过了稀有料理",
    you_missed_d: "你错过了收藏娃娃",
    you_missed_x: "你错过了稀有收藏品",
    openIn: "开店于",
    closeIn: "关店于",
    goToTheEvent: "前往活动",
    basicItems: "初级装备",
    drawing: "工作室特供",
    monthly: "月度精选",
    event: "活动精选",
    tower: "数学塔特供",
    go: "赚取",
  },
  title: {
    gallery: "画廊",
    tower: "数学塔",
    town: "小镇",
    shop: "商店",
    wardrobe: "衣柜",
    hamochi_cup: "活动",
    game: "活动",
    level: "关卡",
    locksmith: "锁匠",
    select_school: "选择你的学校",
    studio: "工作室",
  },
  tutorial: {
    energy: {
      dialog_1: "哦！ 你的能量很低。",
      dialog_2: "让我们充电。",
      dialog_3: "点击这里进入大楼。",
      dialog_4: "点击这里为您补充能量",
      dialog_5: "是的。 你的能量被充电了。",
      dialog_6: "您可以每天一次来这里补充能量。",
      dialog_7: "点击这里导航到镇岛",
      dialog_8: "看来您已经为这一天充电了。 明天试试。",
    },
    events: {
      dialog_1: "看，那里似乎很热闹！",
      dialog_2: "点击这里进入。",
      dialog_3: "点击这里去小镇岛。",
      dialog_4: "哇，今天有活动呢！一起报名参加吧。",
      dialog_5: "点击「马上参加」。",
      dialog_6: "这次我们试试简易版吧。",
      dialog_7: "这次我们试试完整版吧。",
    },
    exploreIntro: {
      dialog_1: "你好，我是Hamochi。",
      dialog_2: "欢迎来到 Hamochi World。",
      dialog_3: "我们热爱学习和玩耍。",
    },
    exploreEvents: {
      dialog_1: "看，那里似乎很热闹！",
      dialog_2: "点击这里进入。",
      dialog_3: "点击「马上参加」。",
    },
    exploreMining: {
      dialog_1: "让我们学习如何开采宝石！",
      dialog_2: "点击这里开始挖矿。",
    },
    exploreForaging: {
      dialog_1: "一起来学习如何收集水果吧！",
      dialog_2: "单击此处开始。",
    },
    exploreCooking: {
      dialog_1: "我们来学习如何做饭吧！",
      dialog_2: "单击此处开始烹饪。",
    },
    exploreKombat: {
      dialog_1: "让我们一起来学习如何使用魔法吧！",
      dialog_2: "单击此处开始。",
    },
    exploreFishing: {
      dialog_1: "我们来学习如何钓鱼吧！",
      dialog_2: "点击此处开始游戏教程。",
    },
    exploreLevel: {
      dialog_1: "现在我将教你如何开始一个关卡",
      dialog_2: "点击此处选择关卡",
      dialog_4: "单击此处开始。",
    },
    exploreShop: {
      dialog_1: "商店开门了。",
      dialog_2: "点击此处进入菜单。",
      dialog_3: "点击此处进入商店。",
      dialog_4_1: "你可以使用获得的硬币来购物。",
      dialog_4_2: "哦，恭喜！ 你已经有一顶帽子了！",
      dialog_5: "让我们试试穿上这个小帽子。",
      dialog_6: "先点击选择帽子。",
      dialog_7: "然后点击购买。",
      dialog_8: "好的！ 购买成功。",
      dialog_9: "恭喜！ 你获得了一顶新帽子！",
      dialog_10: "商店里还有很多物品， 可以再回来购买哦！",
      dialog_11: "点击此处回去",
    },
    intro: {
      dialog_1: "你好，我是Hamochi。",
      dialog_2: "欢迎来到 Mochi Islands。",
      dialog_3: "我们热爱学习和玩耍。",
    },
    fishing_achievements: {
      dialog_1: "恭喜你完成了教程。",
      dialog_2: "我为你准备了一份惊喜！",
      dialog_3: "点击这里查看您的成就。",
      dialog_4: "这是给你的小奖励。",
      dialog_5: "点击这里领取您的奖励。",
      dialog_6: "恭喜！您似乎领取了您的奖励。",
      dialog_7: "您可以解锁其他成就以获得更多奖励。",
      dialog_8: "接下来，我将教你如何使用你的奖励！",
      dialog_9: "我们返回小镇岛吧。",
      dialog_10: "点击这里。",
    },
    fishing_level_one: {
      dialog_1: "让我们尝试第一关。",
      dialog_2: "点击这里开始。",
    },
    fishing_tutorial: {
      dialog_1: "哦，有钓鱼比赛呢。",
      dialog_2: "我们一起学钓鱼吧！",
      dialog_3: "点击这里。",
      dialog_4: "鱼儿上钩了！让我们解决它出的难题。",
      dialog_5: "选择正确的答案。",
      dialog_6: "答得好！",
      dialog_7: "可惜，错了。。。",
      dialog_8: "它究竟躲在哪个洞里呢？",
      dialog_9: "恭喜！你钓到了第一条鱼。",
      dialog_10: "我们再练习一次吧。",
      dialog_11: "不错不错，你已经学会了。",
      dialog_12: "祝你一切顺利！",
      dialog_13: "哎呀！鱼儿躲进其中一个洞里了。",
      dialog_14: "点击蝴蝶捕捉它。",
      dialog_15: "恭喜！你学会如何捕捉蝴蝶了。",
      dialog_16: "哦，这次是捉虫大赛呢。",
      dialog_17: "让我教您如何捉虫吧！",
      dialog_18: "首先我们需要回答数学题。",
      dialog_19: "蝴蝶出现了，快抓呀！",
    },
    pick_shop: {
      dialog_1: "商店开门了。",
      dialog_2: "进去看看吧。",
      dialog_3: "点击这里进入商店。",
      dialog_4: "您可以使用金币来购物。",
      dialog_5: "让我们试穿看看。",
      dialog_6: "非常合身，就是它了！",
      dialog_7: "恭喜！你有了新帽子！",
      dialog_8: "还有好多美丽的服装呢。",
      dialog_9: "哦，你已经有帽子了！",
      dialog_10: "点击这里返回小镇岛。",
    },
    return_events: {
      dialog_1: "该继续加油了。",
      dialog_2: "点击这里。",
      dialog_3: "点击这里继续。",
      dialog_4: "选择你喜欢的版本。",
      dialog_5: "点击这里到小镇岛。",
      dialog_6: "点击此处继续。",
    },
  },
  view: {
    Island: {
      Citizen: "学生",
      Classroom: "课室",
      Home: "家园",
      Market: "市场",
      Playground: "操场",
      Teacher: "老师",
      Town: "小镇",
      Building: {
        BugCatcher: "Bug Catcher",
        Events: "活动会场",
        Energy: "发电站",
        Garden: "花园",
        House: "房子",
        LuckyEgg: "幸运蛋",
        Mayor: "老师",
        Mission: "功课",
        News: "消息",
        Report: "成绩单",
        School: "学校",
        Shop: "商店",
      },
    },
    PageBuildingMission: {
      bonusRewardsMessage: "奖励奖励结束",
      claimRewardMessage: "领取您的奖励。",
      completedMessage: "完成于",
      completedPastDueMessage: "逾期完成",
      deleteMissionMessage: "您确定要删除此任务吗？",
      dueMessage: "由于上",
      pageAddTitle: "新的功课",
      pageEditTitle: "编辑任务",
      pagePerformanceTitle: "学生表现",
      rewardNotReceivedMessage: "你什么也没有",
      rewardReceivedMessage: "你获得了",
    },
    PageBugcatcherHome: {
      bigBugCaught: "你抓住了{Bugname}！",
      bigBugMissed: "你错过了大虫！下次试试。",
      bigBugWarning: "警告！",
      bigBugDes: "单击大虫5次以捕获它。",
      bossDetected: "发现大虫",
      bugCatch: "这个回合你抓到了{count}只蝴蝶。",
      bugMissed: "这个回合你没有抓到任何蝴蝶。",
      difficultyBonus: "难度奖金",
      gameTitle: "点击并捕捉蝴蝶。",
      getReady: "做好准备",
      go: "开始",
      points: "积分",
      questionEndCorrectMessage: "恭喜！ 您选择了正确的答案。",
      questionEndWrongMessage: "哎呀！ 您没有选择正确的答案。",
      roundEndMessage: "提问时间...",
    },
    PageErrorNotFound: {
      title: "报告班长，我们失踪了。",
      description: "此网页不存在。",
    },
    PageErrorSessionExpired: {
      title: "登入证过期了。",
      description: "为保护你的账号，请再次登入。",
    },
    PageEntranceStudentAvatarSelect: {
      title: "创建角色",
    },
    PageEntranceStudentClassroom: {
      title: "选择你的名字",
      stringNoAccount: "没有你的名字？",
      search: "搜索你的名字",
    },
    PageEntranceStudentMain: {
      fullVersionGameTitle: "你想访问完整版的游戏吗？",
      title: "登入",
      stringCodeEmpty: "请输入代码。",
      stringCodeIncomplete: "代码不完整。",
    },
    PageEntranceStudentPin: {
      stringCodeEmpty: "PIN 缺失。",
      stringCodeIncomplete: "PIN 不完整。",
      title: "输入您的 PIN",
    },
    PageEntranceStudentPasswordSelect: {
      title: "选择你的密码钥匙",
    },
    PageEntranceStudentRegister: {
      title: "注册账号",
    },
    PageEventLanding: {
      fast: "很快",
      slow: "很慢",
      textInternet: "网速",
      textStatusEntering: "即将进入。。。",
      textStatusInit: "启动中。。。",
      textStatusTest: "测试网速中。。。 ",
      textVersionFull: "完整版",
      textVersionFullDescription: "大约需要",
      textVersionFullDescriptionMins: "分钟",
      textVersionLite: "简易版",
      textVersionLiteDescription: "推荐",
    },
    PageGuestSchool: {
      title: "选择你的班级",
      search: "搜索你的教室",
    },
    PageParticipantLanding: {
      countdown_end: "结束于",
      countdown_ended: "已经结束了",
      countdown_start: "倒数",
      countdown_time_days: "天",
      countdown_time_hours: "时",
      countdown_time_minutes: "分",
      countdown_time_seconds: "秒",
    },
    PageSleeping: {
      message:
        "该睡觉了。 Hamochi 和他的朋友们从晚上 11 点开始睡觉。早上7点回来。",
    },
    PageStudentClassmate: {
      title: "我的同学",
      description: "",
    },
    PageStudentHome: {
      title: "家",
      description: "你想让我做什么",
    },
    PageStudentLanding: {
      title: "家里",
      description: "你想去哪里呢？",
    },
    PageStudentMedal: {
      title: "我的奖牌",
      description: "",
    },
    PageStudentShop: {
      title: "商店",
      description: "你想买什么呢？",
    },
    PageStudentStadium: {
      title: "体育馆",
      description: "你想参加哪一个活动呢？",
    },
    PageStudentWardrobe: {
      title: "衣柜",
      description: "你想穿什么？",
    },
  },
};
