export default {
  state: {
    data: false,
  },
  getters: {
    //
  },
  actions: {
    resetBoss(context) {
      context.commit("updateBoss", false)
    },
  },
  mutations: {
    updateBoss(state, data) {
      return state.data = data
    }
  }
}