export default {
  state: {
    data: false,
  },
  getters: {
    //
  },
  actions: {
    resetSeries(context) {
      context.commit("updateSeries", false)
    },
  },
  mutations: {
    updateSeries(state, data) {
      return state.data = data
    }
  }
}