import Vue from 'vue'
import roman from 'roman-numerals'

Vue.mixin({
	methods: {
		$_ucfirst(string) {
			return string.charAt(0).toUpperCase() + string.slice(1)
		},
		$_lcfirst(string) {
			return string.charAt(0).toLowerCase() + string.slice(1)
		},
		$_toRoman(integer) {
			return roman.toRoman(integer)
		},
	}
})