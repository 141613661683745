<template>
  <transition name="fade" mode="out-in">
    <div>
      <v-app id="main-app-mockup" class="mockup">
        <v-main>
          <v-container
            style="
              max-width: 480px;
              padding: 0;
              height: 100%;
              overflow: hidden;
              position: relative;
            "
          >
            <transition
              :name="transitionName"
            >
              <router-view :key="$route.fullPath"></router-view>
            </transition>
          </v-container>
        </v-main>
      </v-app>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  components: {
    //
  },
  data() {
    return {
      transitionName: "fade",
    };
  },
  watch: {
    $route(to) {
      this.updateTransition(to);
    },
  },
  methods: {
    updateTransition(route) {
      if (route.query.transition) {
        this.transitionName = route.query.transition;
      } else {
        this.transitionName = "fade";
      }
    },
  },
  mounted() {
    this.updateTransition(this.$route);
  },
};
</script>

<style>
#main-app-mockup {
  background: transparent !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgb(255, 255, 255) !important;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.5s ease;
}
.slide-left-enter {
  opacity: 0.5;
  position: absolute;
}
.slide-left-leave-to {
  transform: translateX(-100%);
  opacity: 0.5;
  position: absolute;
  z-index: 99 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.5s ease;
}
.slide-right-enter {
  transform: translateX(-100%);
  opacity: 0.5;
  position: absolute;
  z-index: 99 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.slide-right-leave-to {
  opacity: 0.5;
  position: absolute;
}

.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: all 0.5s ease;
}
.slide-bottom-enter {
  transform: translateY(-100%);
  /* opacity: 0; */
  position: absolute;
  z-index: 99 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.slide-bottom-leave-to {
  /* transform: translateY(100%); */
  /* opacity: 0; */
  position: absolute;
}

.slide-top-enter-active,
.slide-top-leave-active {
  transition: all 0.5s ease;
}
.slide-top-enter {
  /* transform: translateY(-100%); */
  /* opacity: 0; */
  position: absolute;
}
.slide-top-leave-to {
  transform: translateY(-100%);
  /* opacity: 0; */
  position: absolute;
  z-index: 99 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
</style>