export default {
  state: {
    data: false,
  },
  getters: {
    //
  },
  actions: {
    logout(context) {
      context.commit("updateAuth", false);
    },
  },
  mutations: {
    updateAuth(state, data) {
      if (data == false) {
        var code = state.data.Group.code;
        var classId = null;
        if (state.data.Classroom) {
          classId = state.data.Classroom.id;
        }
        if (state.data.Player) {
          var mochiId = state.data.Player.mochiId;
        }

        data = {
          Group: {
            code: code,
          },
          Session: false,
          Classroom: {
            id: classId,
          },
          Player: {
            mochiId: mochiId,
          },
        };
      }
      return (state.data = data);
    },
  },
};
