<template>
  <transition name="fade">
    <div
      v-if="isLoading"
      class="text-center ma-5"
    >
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </div>
  </transition>
</template>

<script>
  export default {
    props:['isLoading']
  }
</script>
