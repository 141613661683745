<template>
  <transition name="fade">
    <div
      v-if="condition"
    >
      <v-card class="text-center">
        <v-card-text>{{$t("string.noItem")}}</v-card-text>
      </v-card>
    </div>
  </transition>
</template>

<script>
  export default {
    props:['condition']
  }
</script>
