export default {
  state: {
    data: false,
  },
  getters: {
    //
  },
  actions: {
    resetCoin(context) {
      context.commit("updateCoin", false)
    },
  },
  mutations: {
    updateCoin(state, data) {
      return state.data = data
    }
  }
}