import Vue from "vue";

Vue.mixin({
  methods: {
    $_parseSchoolYear(year) {
      return (
        this.$t("string.schoolYear_prefix") +
        year +
        this.$t("string.schoolYear_suffix")
      );
    },
    $_formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = date.getUTCFullYear();
      return `${day}-${month}-${year}`;
    },
  },
});
