import LayoutMockup from "@/layouts/LayoutMockup.vue";

export default {
  path: "/mockup",
  component: LayoutMockup,
  children: [
    {
      path: "abc/extra/:item",
      name: "ABCExtra",
      component: () => import(`@/mockups/ABC/extra/extra`),
    },
    {
      path: "abc/book-1/r1",
      name: "ABCBook1",
      component: () => import(`@/mockups/ABC/book-1/book`),
    },
    {
      path: "audio-test",
      name: "ABCPageTest",
      component: () => import(`@/mockups/ABC/book-1/pages/page-test`),
    },
    {
      path: "abc/book-1/1",
      name: "ABCPage1",
      component: () => import(`@/mockups/ABC/book-1/pages/page-1`),
    },
    {
      path: "abc/book-1/2",
      name: "ABCPage2",
      component: () => import(`@/mockups/ABC/book-1/pages/page-2`),
    },
    {
      path: "abc/book-1/3",
      name: "ABCPage3",
      component: () => import(`@/mockups/ABC/book-1/pages/page-3`),
    },
    {
      path: "abc/book-1/4",
      name: "ABCPage4",
      component: () => import(`@/mockups/ABC/book-1/pages/page-4`),
    },
    {
      path: "abc/book-1/5",
      name: "ABCPage5",
      component: () => import(`@/mockups/ABC/book-1/pages/page-5`),
    },
    {
      path: "abc/book-1/6",
      name: "ABCPage6",
      component: () => import(`@/mockups/ABC/book-1/pages/page-6`),
    },
    {
      path: "abc/book-1/7",
      name: "ABCPage7",
      component: () => import(`@/mockups/ABC/book-1/pages/page-7`),
    },
    {
      path: "abc/book-1/8",
      name: "ABCPage8",
      component: () => import(`@/mockups/ABC/book-1/pages/page-8`),
    },
    {
      path: "abc/book-1/9",
      name: "ABCPage9",
      component: () => import(`@/mockups/ABC/book-1/pages/page-9`),
    },
    {
      path: "abc/book-1/10",
      name: "ABCPage10",
      component: () => import(`@/mockups/ABC/book-1/pages/page-10`),
    },
    {
      path: "abc/book-1/11",
      name: "ABCPage11",
      component: () => import(`@/mockups/ABC/book-1/pages/page-11`),
    },
    {
      path: "abc/book-1/12",
      name: "ABCPage12",
      component: () => import(`@/mockups/ABC/book-1/pages/page-12`),
    },
    {
      path: "abc/book-1/13",
      name: "ABCPage13",
      component: () => import(`@/mockups/ABC/book-1/pages/page-13`),
    },
    {
      path: "abc/book-1/14",
      name: "ABCPage14",
      component: () => import(`@/mockups/ABC/book-1/pages/page-14`),
    },
    {
      path: "abc/book-1/15",
      name: "ABCPage15",
      component: () => import(`@/mockups/ABC/book-1/pages/page-15`),
    },
    {
      path: "abc/book-1/16",
      name: "ABCPage16",
      component: () => import(`@/mockups/ABC/book-1/pages/page-16`),
    },
    {
      path: "abc/book-1/17",
      name: "ABCPage17",
      component: () => import(`@/mockups/ABC/book-1/pages/page-17`),
    },



    {
      path: "abc/book-1/phaser/1",
      name: "ABCScreen1",
      component: () => import(`@/mockups/ABC/book-1/screens/screen-1`),
    },
    {
      path: "abc/book-1/phaser/2",
      name: "ABCScreen2",
      component: () => import(`@/mockups/ABC/book-1/screens/screen-2`),
    },
    {
      path: "abc/book-1/phaser/3",
      name: "ABCScreen3",
      component: () => import(`@/mockups/ABC/book-1/screens/screen-3`),
    },
    {
      path: "abc/book-1/phaser/4",
      name: "ABCScreen4",
      component: () => import(`@/mockups/ABC/book-1/screens/screen-4`),
    },
    {
      path: "abc/book-1/phaser/5",
      name: "ABCScreen5",
      component: () => import(`@/mockups/ABC/book-1/screens/screen-5`),
    },
  ],
};
