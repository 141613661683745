<template>
<router-view style="height: 100% !important"/>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
html {
  background-blend-mode: lighten;
  background-image: url("assets/background/BGTextureLite.jpg");
  background-size:cover;
}
</style>
