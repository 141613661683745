export default {
    state: {
      data: false,
    },
    getters: {
      //
    },
    actions: {
      resetSettings(context) {
        context.commit("updateSettings", false)
      },
    },
    mutations: {
      updateSettings(state, data) {
        return state.data = data
      }
    }
  }